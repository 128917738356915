import React, { Component } from 'react'
import Header from './Header'
import Footer from './Footer'
import VideoBG from './VideoBG'
import SubscribeWindow from './SubscribeWindow'

class App extends Component {
  state = {
    sound: false,
    subscribe: false
  }

  render() {
    const { sound, subscribe } = this.state

    return (
      <div className='app'>
        <Header sound={sound} soundHandler={sound ? this.disableSound : this.enableSound}/>
        <Footer openSubscribeWindow={this.openSubscribeWindow} />
        <VideoBG sound={sound} />
        {
          subscribe &&
          <SubscribeWindow closeSubscribeWindow={this.closeSubscribeWindow} />
        }
      </div>
    )
  }

  openSubscribeWindow = () => this.setState({ subscribe: true })

  closeSubscribeWindow = () => this.setState({ subscribe: false })

  enableSound = () => this.setState({ sound: true })

  disableSound = () => this.setState({ sound: false })
}

export default App
