import React from 'react'
import { ReactComponent as FOKLogo } from '../svg/f-o-k-logo.svg'
import { ReactComponent as FBLogo } from '../svg/fb.svg'

const Footer = ({ openSubscribeWindow }) => {
  return (
    <footer className='footer'>
      <div className='footer__social'>
        <a href='https://www.facebook.com/pages/category/Artist/Олег-Каравайчук-и-Фонд-Олега-Каравайчука-1696041127333165/'
           title='Facebook'
           target='_blank'
           rel='noopener noreferrer'>
          <FBLogo title='Facebook' />
        </a>
      </div>
      <div className='footer__subscribe'>
        <button onClick={openSubscribeWindow}>
          Новости
        </button>
      </div>
      <div className='footer__fok'>
        <a href='http://f-o-k.org'
           title='Фонд Каравайчука'
           target='_blank'
           rel='noopener noreferrer'>
          <FOKLogo title='Фонд Каравайчука' />
        </a>
      </div>
    </footer>
  )
}

export default Footer
