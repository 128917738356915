import React, { Component, Fragment } from 'react'
import validator from 'email-validator'
import Fade from 'react-reveal/Fade'
import InputBlock from './InputBlock'
import { addSubsctiption } from '../firebase/fb'
import { getISOTimeString } from '../utils'

class SubscribeWindow extends Component {
  state = {
    email: '',
    emailAdded: false,
    loading: false,
    error: null,
    errors: {}
  }

  render() {
    const { closeSubscribeWindow } = this.props
    const { email, emailAdded, loading, error, errors } = this.state
    let modalData = null

    if (loading) {
      modalData = <div className='subscribe-window__form'>Загрузка...</div>
    } else if (emailAdded) {
      modalData = <div className='subscribe-window__form'><div>Спасибо! Вы добавлены в рассылку.</div></div>
    } else {
      modalData =
        <form className='subscribe-window__form' onSubmit={this.onSubmit} onClick={this.formClick}>
          <InputBlock
            label='Подпишитесь на наши новости:'
            errors={errors}
            placeholder='Ваш Email'
            handler={this.onInputChange}
            name='email'
            value={email}
            type='text'
          />
          { error && <p className='error-field'>{error}</p> }
          <p className='subscribe-window__submit'>
            <button type='submit' className='submit-btn'>
              Подписаться
            </button>
          </p>
        </form>
    }

    return (
      <Fragment>
        <div className='subscribe-window' onClick={loading ? null : closeSubscribeWindow}>
          <Fade bottom>
            {modalData}
          </Fade>
        </div>
      </Fragment>
    )
  }

  formClick = event => event.stopPropagation()

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value.toLowerCase()
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.validate()
  }

  validate = () => {
    const { email } = this.state
    const errors = {}

    if (!email) errors.email = 'Обязательное поле'
    if (email && !validator.validate(email)) errors.email = 'Неверный формат'

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      this.setState({
        errors: errors,
        loading: true,
      }, this.addSubscription)
    }
  }

  addSubscription = () => {
    const { email } = this.state
    const newEmail = { email: email.toLowerCase(), createdDate: getISOTimeString() }
    const key = email.replace(/[.]/g, ',')

    addSubsctiption(key, newEmail)
      .then(snapshot => {
        this.setState({ emailAdded: true, loading: false })
      })
      .catch(error => {
        this.setState({
          error: error.message,
          loading: false
        })
      })
  }
}

export default SubscribeWindow
