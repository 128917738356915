const appName = 'karavaychuk-671d0'

export const config = {
  apiKey: 'AIzaSyD90fTewD7mym0g3ffCT6tSNgd4AZotFFQ',
  authDomain: `${appName}.firebaseapp.com`,
  projectId: appName,
  storageBucket: `${appName}.appspot.com`,
  messagingSenderId: '356519093439',
  appId: '1:356519093439:web:609da20b11b2fd62fc520a',
  measurementId: 'G-KG3HQ4CXF2',
  databaseURL: 'https://karavaychuk-671d0-default-rtdb.europe-west1.firebasedatabase.app/'
}
