import React from 'react'
import { ReactComponent as SoundOffIcon } from '../svg/sound-on.svg'
import { ReactComponent as SoundOnIcon } from '../svg/sound-off.svg'

const Header = ({ sound, soundHandler }) => {
  return (
    <header className='header'>
      <div className='header__message'>
        САЙТ В РАЗРАБОТКЕ
      </div>
      <div className='header__sound'>
        <button onClick={soundHandler}>
          {sound ? <SoundOnIcon title='ВЫКЛ.'/> : <SoundOffIcon title='ВКЛ.'/>}
        </button>
      </div>
    </header>
  )
}

export default Header
