import React from 'react'

const InputBlock = ({ label, errors, handler, name, value, type, placeholder, onInputBlur }) => {
  const errorClass = errors[name] ? 'error-field error-field--visible' : 'error-field'
  const inputClass = errors[name] ? 'input-text input-text--error' : 'input-text '

  return (
    <div>
      {
        label &&
        <label htmlFor={name} className='label'>
          {label}
        </label>
      }
      <div className='input-block'>
        <input
          id={name}
          value={value}
          onChange={handler(name)}
          className={inputClass}
          type={type}
          placeholder={placeholder || ''}
          onBlur={onInputBlur}
        />
        <span className={errorClass}>
          {errors[name]}
        </span>
      </div>
    </div>
  )
}

export default InputBlock
