import React from 'react'

const VideoBG = ({ sound }) => {
  return (
    <div className='video-bg'>
      <div className='video-bg__overlay'></div>
      <video className='video-bg__video' playsInline autoPlay muted={!sound} loop>
        <source
          src='https://firebasestorage.googleapis.com/v0/b/karavaychuk-671d0.appspot.com/o/karavaichuk-video-small.mp4?alt=media&token=2160da80-03cc-4908-a34a-c7754693ca47'
          type='video/mp4'
        />
      </video>
    </div>
  )
}

export default VideoBG
