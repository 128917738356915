import { initializeApp } from 'firebase/app'
import { getDatabase, ref, set } from 'firebase/database';
import { config } from '../config'

const app = initializeApp(config)

function addSubsctiption(key, email) {
  const db = getDatabase();
  return set(ref(db, 'subscriptions/' + key), email)
}

export {
  addSubsctiption
}
